@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
.messageBox {
  padding:0 2.5vh;
  color: white;
  display: inline-block;
  max-width: 75%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 2.2vh;
  margin-top: auto;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0 2%;
  margin-top: 1vh;
  
}

.sentText {
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(105, 105, 105);
  text-transform: lowercase;
  font-size:2vh;
  margin-top: auto;
}

.pl-10 {
  padding-left: 1vh;
}

.pr-10 {
  padding-right: 1vh;
}

.justifyStart {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.justifyEnd {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #28142F;
  border-radius: 1vh;
}

.backgroundLight {
  background: #FF3314;
  border-radius:1vh;
}

.css-1qyrjaz {
  width:1.5vh;
  height: 2vh;
  right:0;
  bottom: 0;
}
.messages {
  padding: 0 0;
  overflow: auto; 
  height:75%;
  width:90%;
  margin: 6vh auto;
  scrollbar-width: none;  
}
.infoBar {
  background: transparent;
  height: 100%;
  width: 20%;
  border-right: 1px solid #cccccc;
  position: absolute;
  top:0;
  background-color: white;
  z-index: 55;
  border-radius:7vh 0 0vh 7vh;
}
.infoBar .title {
  color: #28142F;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 3vh;
  position: relative;
  top:2vh;
  padding:1vh 0 1vh 0;
}
.yourInfo img {
  width:100%;
  height: 100%;
}




.wrp-yi {
  width:100%;
  height: 30vh;
  background-image: url(/static/media/qq.8a66613e.svg);
  background-size: cover;
  background-position:center;
  background-repeat: no-repeat;
  position: relative;
  top:2vh;
}
.wrp-yi .room {
  color:#E2E2E2;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  text-transform: capitalize;
  font-size:4vh;
  position: relative;
  top:5vh;
}
.wrp-yi .name {
  color:#FFFFFF;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  font-size:4.4vh;
  letter-spacing: .2vh;
}
.into-yi {
  position: absolute;
  bottom: 0vh;
  left: 3vh;
}

.activeContainer h2 {
  color:#959595;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size:3.5vh;
  text-transform: capitalize;
}
.activeContainer {
  width: 90%;
  overflow-x: hidden;
  margin-top:6vh;
  margin-left:2vh;
}
.overF::-webkit-scrollbar {
  width: 1vh;
}
.overF::-webkit-scrollbar-thumb {
  background: #959595; 
  border-radius: 5vh;
}

.overF {
  overflow: auto;
  height: 40%;
  overflow-x: hidden;
}
.activeItem {
  cursor: default;
  display: -webkit-flex;
  display: flex;
  margin-left:3vh;
  padding-bottom:3vh;
}
.activeImage {
  width: 4.5vh;
  padding-right: 1.5vh;
}





::-webkit-scrollbar {
  width: 1.5vh;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: #28142F; 
  border-radius: 10px;
  cursor: pointer;
}


.inp {
  position:absolute;
  bottom: 4%;
  width:100%;
}
.form {
  width:90%;
  margin:0 auto;
}
.input {
  border: none;
  border-radius: 0;
  width: 73%;
  font-size: 1.8vh;
  border-radius:5vh;
  padding:2vh 4vh;
  border: 1px solid #ccc;
  margin: 0 auto;
  text-transform: none;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.sendButton {
  color: #fff !important;
  text-decoration: none;
  background: #28142F;
  padding: 2vh;
  display: inline-block;
  border: none;
  width: 25%;
  margin-left:2%;
  font-size:1.8vh;
  border-radius:5vh;
}
.Back {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width:100%;
  height:100%;
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.back_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #28142F;
  width:4vh;
  height:4vh;
}
.Back .title {
  color: #28142F;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 4vh;
  visibility: hidden;
}
.back_wrp div {
  margin-left: 3vh;
}


@media(max-width:500px) {
 .Back .title {
  visibility: visible;
 }
 .back_btn {
  position: relative;
  top: 0%;
  left: 0%;
  -webkit-transform: translate(0);
          transform: translate(0);
 }
}
.container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  background-color: #DEDEDE;
}
.content {
  background: white;
  width:85%;
  height:85%;
  position: relative;
  border-radius:7vh;
}
.content_messages {
  width:70%;
  height: 100%;
  position: absolute;
  top:50%;
  left:60%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
  padding-top:3px;
}
.content_back {
  position: absolute;
  width:10%;
  height:15%;
  background: transparent;
  top:0;
  z-index: 15;
  right:0;
  
}
.content_info_bar {
  height:100%;
}





/* Responsivites */


@media(max-width: 1000px) {
  .content {
    width:100%;
    height:100%;
    border-radius: 0;
  }
}

@media(max-width: 900px) {
  .infoBar {
    height:100%;
    width:30%;
  }
  .content_messages {
    width:70%;
    left:65%;
  }
  .messageBox {   
    max-width:80%;
  }
  .content_back {
    height: 5%;
}
}

@media(max-width: 500px) {
  .infoBar {
    height:90%;
    position: absolute;
    top: 10%;
    width:100%;
    display:none;
  }
  .infoBar .title {
    display: none;
  }
  .yourInfo img {
    width:100%;
    height: 100%;
  }
  
  .wrp-yi {
    height: 40vh;
  }
  .wrp-yi .room {
    font-size:6vh;
    top:8vh;
  }
  .wrp-yi .name {
    font-size:7.5vh;
  }
  .into-yi {
    bottom: -4vh;
  }
  
  .activeContainer h2 {
    font-size:6vh;
  }
  .activeImage {
    width: 6vh;
  }
  
  .content_messages {
    width:100%;
    height:90%;
    left:0;
    top:10%; 
    -webkit-transform: translate(0); 
            transform: translate(0);
  }
  .content_back {
    width: 100%;
    height:10%;
    position: absolute;
    background-color: #FFF;
    border-bottom: 1px solid #DEDEDE;
  }
  .show {
    display: block;
  }
}



html, body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  background-color: #DEDEDE;
  overflow: hidden;
}

#root {
  height:100vh;
}

* {
  box-sizing: border-box;
 
}

.container_join {
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height:100vh;
  margin:0;
  padding:0;
}

.wrapper_join {
  background-color: white;
  width:900px;
  border-radius:30px;
  display:-webkit-flex;
  display:flex;
  position: relative;
  padding:50px 0 50px 0;
}

.image_join  {
  position: relative;
  width:50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;  
}

.image_join img {
  position: relative;
  width:70%;
}

.into_join {
  width:50%;
  padding:0 0 0 80px;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.heading {
  color: #28142F;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size:50px;
  margin:0;
  padding:0;
}
.text {
  color: #bbb;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  font-size:15px;
  width:230px;
  position: relative;
  margin:0;
  padding:0;
  padding-bottom: 15px;
}
input {
  font-size:15px;
  padding:15px 25px;
  border-radius: 5vh;
  border:1px solid #DEDEDE;
  text-transform: capitalize;
  width:250px;
  margin-top:15px;
}
button {
  background-color: #28142F;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size:15px;
  border: none;
  outline: none;
  border-radius: 5vh;
  width:250px;
  padding:15px 0;
  cursor: pointer;
  margin-top:15px;

}
button:focus {
  outline: 0;
}

.shape {
  width:50%;
  height:100%;
  background: #28142F;
  border-radius: 100%;
  position: absolute;
  bottom:-60%;
  left: -15%;
}

.shape_2 {
  width:50%;
  height:100%;
  background: #FF3314;
  border-radius: 100%;
  position: absolute;
  top:-50%;
  right: -20%;
}




@media (max-width:1130px) {
  .shape {
    left:-30%;
    bottom:-50%;
  }
  .shape_2 {
    top:-30%;
    right: -30%;
  }
}

@media (max-width:1030px) {
  .wrapper_join {
    width:80%;
    padding:60px 0 60px 0;
  } 
  .into_join {
    padding:0 0 0 50px;
  }
  .heading {
    font-size:46px;
    line-height: 45px;
    padding-bottom: 10px;
  }
  input {
    font-size:13px;
    padding:10px 25px;
    width:200px;
  }
  button {
    width:200px;
    padding:10px 0;
  } 
}


@media (max-width:800px) and (max-width:1000px){
  .shape {
    bottom:-10%;
    left: -20%;
    height:50%;
  }
  .shape_2 {
    top:-10%;
    right: -20%;
    height:50%;
  }
}




@media (max-width:650px) {
  .wrapper_join {
    width:80%;
    padding:60px 0 60px 0;
  } 
  .image_join {
    display: none;
  }
  .into_join {
    padding:0 0 0 00px;
    width:100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .heading {
    font-size:30px;
    line-height: 45px;
    padding-bottom: 10px;
    text-align: center;
  }
  .text {
    text-align: center;
    font-size:10px;
    width:150px;
  }
  input {
    font-size:10px;
    padding:12px 25px;
    width:100%;
    margin-top:5px;
  }
  button {
    width:170px;
    margin-top:5px;
    padding:10px 0;
    font-size: 10px;
  }
  .shape {
    display:none;
  }
  .shape_2 {
    display:none;
  }
}

@media (min-width: 1700px) {
  .wrapper_join {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@media (min-width: 1900px) {
  .wrapper_join {
    width:70%;
    padding:7vh 0 7vh 0;
    -webkit-transform: scale(1);
            transform: scale(1);
    border-radius: 5vh;
  }
  .into_join {
    padding-left: 15vh;
    padding-top: 2vh;
  }
  .heading {
    font-size:7vh;
  }
  .text {
    font-size:2vh;
    width:55%;
  }
  input {
    font-size:2vh;
    padding:2vh 2.5vh;
    width:35vh;
    margin-top:2vh;
  }
  button {
    width:35vh;
    margin-top:2vh;
    padding:2vh 0;
    font-size: 2vh;
  }
}
html, body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  background-color: #DEDEDE;
  overflow: hidden;
}

#root {
  height:100vh;
}

* {
  box-sizing: border-box;
 
}

.container_join {
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height:100vh;
  margin:0;
  padding:0;
}

.wrapper_join {
  background-color: white;
  width:900px;
  border-radius:30px;
  display:-webkit-flex;
  display:flex;
  position: relative;
  padding:50px 0 50px 0;
}

.image_join  {
  position: relative;
  width:50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;  
}

.image_join img {
  position: relative;
  width:70%;
}

.into_join {
  width:50%;
  padding:0 0 0 80px;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.heading {
  color: #28142F;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size:50px;
  margin:0;
  padding:0;
}
.text {
  color: #bbb;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  font-size:15px;
  width:230px;
  position: relative;
  margin:0;
  padding:0;
  padding-bottom: 15px;
}
input {
  font-size:15px;
  padding:15px 25px;
  border-radius: 5vh;
  border:1px solid #DEDEDE;
  text-transform: capitalize;
  width:250px;
  margin-top:15px;
}
button {
  background-color: #28142F;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size:15px;
  border: none;
  outline: none;
  border-radius: 5vh;
  width:250px;
  padding:15px 0;
  cursor: pointer;
  margin-top:15px;

}
button:focus {
  outline: 0;
}

.shape {
  width:50%;
  height:100%;
  background: #28142F;
  border-radius: 100%;
  position: absolute;
  bottom:-60%;
  left: -15%;
}

.shape_2 {
  width:50%;
  height:100%;
  background: #FF3314;
  border-radius: 100%;
  position: absolute;
  top:-50%;
  right: -20%;
}




@media (max-width:1130px) {
  .shape {
    left:-30%;
    bottom:-50%;
  }
  .shape_2 {
    top:-30%;
    right: -30%;
  }
}

@media (max-width:1030px) {
  .wrapper_join {
    width:80%;
    padding:60px 0 60px 0;
  } 
  .into_join {
    padding:0 0 0 50px;
  }
  .heading {
    font-size:46px;
    line-height: 45px;
    padding-bottom: 10px;
  }
  input {
    font-size:13px;
    padding:10px 25px;
    width:200px;
  }
  button {
    width:200px;
    padding:10px 0;
  } 
}


@media (max-width:800px) and (max-width:1000px){
  .shape {
    bottom:-10%;
    left: -20%;
    height:50%;
  }
  .shape_2 {
    top:-10%;
    right: -20%;
    height:50%;
  }
}




@media (max-width:650px) {
  .wrapper_join {
    width:80%;
    padding:60px 0 60px 0;
  } 
  .image_join {
    display: none;
  }
  .into_join {
    padding:0 0 0 00px;
    width:100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .heading {
    font-size:30px;
    line-height: 45px;
    padding-bottom: 10px;
    text-align: center;
  }
  .text {
    text-align: center;
    font-size:10px;
    width:150px;
  }
  input {
    font-size:10px;
    padding:12px 25px;
    width:100%;
    margin-top:5px;
  }
  button {
    width:170px;
    margin-top:5px;
    padding:10px 0;
    font-size: 10px;
  }
  .shape {
    display:none;
  }
  .shape_2 {
    display:none;
  }
}

@media (min-width: 1700px) {
  .wrapper_join {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@media (min-width: 1900px) {
  .wrapper_join {
    width:70%;
    padding:7vh 0 7vh 0;
    -webkit-transform: scale(1);
            transform: scale(1);
    border-radius: 5vh;
  }
  .into_join {
    padding-left: 15vh;
    padding-top: 2vh;
  }
  .heading {
    font-size:7vh;
  }
  .text {
    font-size:2vh;
    width:55%;
  }
  input {
    font-size:2vh;
    padding:2vh 2.5vh;
    width:35vh;
    margin-top:2vh;
  }
  button {
    width:35vh;
    margin-top:2vh;
    padding:2vh 0;
    font-size: 2vh;
  }
}
.container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  background-color: #DEDEDE;
}
.content {
  background: white;
  width:85%;
  height:85%;
  position: relative;
  border-radius:7vh;
}
.content_messages {
  width:70%;
  height: 100%;
  position: absolute;
  top:50%;
  left:60%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
  padding-top:3px;
}
.content_back {
  position: absolute;
  width:10%;
  height:15%;
  background: transparent;
  top:0;
  z-index: 15;
  right:0;
  
}
.content_info_bar {
  height:100%;
}





/* Responsivites */


@media(max-width: 1000px) {
  .content {
    width:100%;
    height:100%;
    border-radius: 0;
  }
}

@media(max-width: 900px) {
  .infoBar {
    height:100%;
    width:30%;
  }
  .content_messages {
    width:70%;
    left:65%;
  }
  .messageBox {   
    max-width:80%;
  }
  .content_back {
    height: 5%;
}
}

@media(max-width: 500px) {
  .infoBar {
    height:90%;
    position: absolute;
    top: 10%;
    width:100%;
    display:none;
  }
  .infoBar .title {
    display: none;
  }
  .yourInfo img {
    width:100%;
    height: 100%;
  }
  
  .wrp-yi {
    height: 40vh;
  }
  .wrp-yi .room {
    font-size:6vh;
    top:8vh;
  }
  .wrp-yi .name {
    font-size:7.5vh;
  }
  .into-yi {
    bottom: -4vh;
  }
  
  .activeContainer h2 {
    font-size:6vh;
  }
  .activeImage {
    width: 6vh;
  }
  
  .content_messages {
    width:100%;
    height:90%;
    left:0;
    top:10%; 
    -webkit-transform: translate(0); 
            transform: translate(0);
  }
  .content_back {
    width: 100%;
    height:10%;
    position: absolute;
    background-color: #FFF;
    border-bottom: 1px solid #DEDEDE;
  }
  .show {
    display: block;
  }
}




.inp {
  position:absolute;
  bottom: 4%;
  width:100%;
}
.form {
  width:90%;
  margin:0 auto;
}
.input {
  border: none;
  border-radius: 0;
  width: 73%;
  font-size: 1.8vh;
  border-radius:5vh;
  padding:2vh 4vh;
  border: 1px solid #ccc;
  margin: 0 auto;
  text-transform: none;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.sendButton {
  color: #fff !important;
  text-decoration: none;
  background: #28142F;
  padding: 2vh;
  display: inline-block;
  border: none;
  width: 25%;
  margin-left:2%;
  font-size:1.8vh;
  border-radius:5vh;
}
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

html, body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  background-color: #DEDEDE;
  overflow: hidden;
}

#root {
  height:100vh;
}

* {
  box-sizing: border-box;
 
}

.container_join {
  display:flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  margin:0;
  padding:0;
}

.wrapper_join {
  background-color: white;
  width:900px;
  border-radius:30px;
  display:flex;
  position: relative;
  padding:50px 0 50px 0;
}

.image_join  {
  position: relative;
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.image_join img {
  position: relative;
  width:70%;
}

.into_join {
  width:50%;
  padding:0 0 0 80px;
  display:flex;
  flex-direction: column;
}

.heading {
  color: #28142F;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size:50px;
  margin:0;
  padding:0;
}
.text {
  color: #bbb;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  font-size:15px;
  width:230px;
  position: relative;
  margin:0;
  padding:0;
  padding-bottom: 15px;
}
input {
  font-size:15px;
  padding:15px 25px;
  border-radius: 5vh;
  border:1px solid #DEDEDE;
  text-transform: capitalize;
  width:250px;
  margin-top:15px;
}
button {
  background-color: #28142F;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size:15px;
  border: none;
  outline: none;
  border-radius: 5vh;
  width:250px;
  padding:15px 0;
  cursor: pointer;
  margin-top:15px;

}
button:focus {
  outline: 0;
}

.shape {
  width:50%;
  height:100%;
  background: #28142F;
  border-radius: 100%;
  position: absolute;
  bottom:-60%;
  left: -15%;
}

.shape_2 {
  width:50%;
  height:100%;
  background: #FF3314;
  border-radius: 100%;
  position: absolute;
  top:-50%;
  right: -20%;
}




@media (max-width:1130px) {
  .shape {
    left:-30%;
    bottom:-50%;
  }
  .shape_2 {
    top:-30%;
    right: -30%;
  }
}

@media (max-width:1030px) {
  .wrapper_join {
    width:80%;
    padding:60px 0 60px 0;
  } 
  .into_join {
    padding:0 0 0 50px;
  }
  .heading {
    font-size:46px;
    line-height: 45px;
    padding-bottom: 10px;
  }
  input {
    font-size:13px;
    padding:10px 25px;
    width:200px;
  }
  button {
    width:200px;
    padding:10px 0;
  } 
}


@media (max-width:800px) and (max-width:1000px){
  .shape {
    bottom:-10%;
    left: -20%;
    height:50%;
  }
  .shape_2 {
    top:-10%;
    right: -20%;
    height:50%;
  }
}




@media (max-width:650px) {
  .wrapper_join {
    width:80%;
    padding:60px 0 60px 0;
  } 
  .image_join {
    display: none;
  }
  .into_join {
    padding:0 0 0 00px;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heading {
    font-size:30px;
    line-height: 45px;
    padding-bottom: 10px;
    text-align: center;
  }
  .text {
    text-align: center;
    font-size:10px;
    width:150px;
  }
  input {
    font-size:10px;
    padding:12px 25px;
    width:100%;
    margin-top:5px;
  }
  button {
    width:170px;
    margin-top:5px;
    padding:10px 0;
    font-size: 10px;
  }
  .shape {
    display:none;
  }
  .shape_2 {
    display:none;
  }
}

@media (min-width: 1700px) {
  .wrapper_join {
    transform: scale(1.2);
  }
}
@media (min-width: 1900px) {
  .wrapper_join {
    width:70%;
    padding:7vh 0 7vh 0;
    transform: scale(1);
    border-radius: 5vh;
  }
  .into_join {
    padding-left: 15vh;
    padding-top: 2vh;
  }
  .heading {
    font-size:7vh;
  }
  .text {
    font-size:2vh;
    width:55%;
  }
  input {
    font-size:2vh;
    padding:2vh 2.5vh;
    width:35vh;
    margin-top:2vh;
  }
  button {
    width:35vh;
    margin-top:2vh;
    padding:2vh 0;
    font-size: 2vh;
  }
}